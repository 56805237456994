// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // SUPERSHIP
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      {
        title: 'overview.index',
        path: PATH_DASHBOARD.overview,
        icon: ICONS.analytics
      },
      // ORDERS
      {
        title: 'orders.index',
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.cart,
        children: [
          { title: 'orders.list', path: PATH_DASHBOARD.orders.list },
          { title: 'orders.create', path: PATH_DASHBOARD.orders.create }
        ]
      },
      { title: 'audits.index', path: PATH_DASHBOARD.audits.root, icon: ICONS.banking },
      { title: 'tickets.index', path: PATH_DASHBOARD.tickets.root, icon: ICONS.chat },
      // SHOPS
      { title: 'senders.index', path: PATH_DASHBOARD.senders.list, icon: ICONS.blog },
      // SHOPS
      { title: 'shops.index', path: PATH_DASHBOARD.shops.list, icon: ICONS.user },
      // SETTINGS
      {
        title: 'settings.index',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.kanban,
        children: [
          { title: 'settings.account', path: PATH_DASHBOARD.settings.account },
          { title: 'settings.password', path: PATH_DASHBOARD.settings.password },
          { title: 'settings.warehouse', path: PATH_DASHBOARD.settings.warehouses },
          { title: 'settings.card', path: PATH_DASHBOARD.settings.cards },
          { title: 'settings.notification', path: PATH_DASHBOARD.settings.notifications },
          { title: 'settings.activity', path: PATH_DASHBOARD.settings.activities }
        ]
      }
    ]
  }
];

export default sidebarConfig;
