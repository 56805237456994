import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Button } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import LoginForm from './LoginForm';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.lighter,
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 2, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title={`${translate('auth.login')} | SuperShip`}>
      <Container maxWidth="sm">
        <ContentStyle>
          <Box
            sx={{ backgroundColor: '#ffffff', padding: 5, borderLeft: 4, borderColor: 'primary.main', borderRadius: 1 }}
          >
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography sx={{ color: 'text.primary' }} variant="h4" gutterBottom>
                  {translate('auth.login')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{translate('app.info')}</Typography>
              </Box>

              <Box
                borderRadius="5%"
                component="img"
                src="/assets/svg/logo_supership_square.svg"
                sx={{ width: 60, height: 60 }}
              />
            </Stack>

            <LoginForm />
          </Box>
        </ContentStyle>
      </Container>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography align="center" variant="h4" sx={{ color: 'primary.main', px: 5, mt: 10, mb: 5 }}>
            {translate('app.slogan')}
          </Typography>
          <img src="/assets/img/banner-login.png" alt="SuperShip" />
        </SectionStyle>
      </MHidden>
    </RootStyle>
  );
}
