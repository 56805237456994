import { format, getTime, formatDistanceToNow, addDays, subDays, startOfMonth, endOfMonth, subMonths } from 'date-fns';
import vi from 'date-fns/locale/vi';

// ----------------------------------------------------------------------

export const DATE_RANGES = [
  {
    id: 1,
    label: 'Hôm Nay',
    sub: fDate(new Date()),
    begin: new Date(),
    end: new Date()
  },
  {
    id: 2,
    label: 'Hôm Qua',
    sub: fDate(subDays(new Date(), 1)),
    begin: subDays(new Date(), 1),
    end: subDays(new Date(), 1)
  },
  {
    id: 3,
    label: '7 Ngày Trước',
    sub: `${fDate(subDays(new Date(), 6))} - ${fDate(new Date())}`,
    begin: subDays(new Date(), 6),
    end: new Date()
  },
  {
    id: 4,
    label: '30 Ngày Trước',
    sub: `${fDate(subDays(new Date(), 29))} - ${fDate(new Date())}`,
    begin: subDays(new Date(), 29),
    end: new Date()
  },
  {
    id: 5,
    label: '90 Ngày Trước',
    sub: `${fDate(subDays(new Date(), 89))} - ${fDate(new Date())}`,
    begin: subDays(new Date(), 89),
    end: new Date()
  },
  {
    id: 6,
    label: 'Tháng Này',
    sub: `${fDate(startOfMonth(new Date()))} - ${fDate(endOfMonth(new Date()))}`,
    begin: startOfMonth(new Date()),
    end: endOfMonth(new Date())
  },
  {
    id: 7,
    label: 'Tháng Trước',
    sub: `${fDate(startOfMonth(subMonths(new Date(), 1)))} - ${fDate(endOfMonth(subMonths(new Date(), 1)))}`,
    begin: startOfMonth(subMonths(new Date(), 1)),
    end: endOfMonth(subMonths(new Date(), 1))
  },
  {
    id: 8,
    label: 'Tùy Chỉnh',
    sub: 'Tùy Chỉnh Thời Gian Bắt Đầu & Kết Thúc',
    begin: subDays(new Date(), 6),
    end: new Date()
  }
];

export function fStandardDate(date) {
  return format(new Date(date), 'yyyy-MM-dd', { locale: vi });
}

export function fEndDate() {
  return new Date();
}

export function fBeginDate() {
  return subDays(new Date(), 6);
}

export function fDate(date) {
  return format(new Date(date), 'dd/MM/yyyy', { locale: vi });
}

export function fTime(date) {
  return format(new Date(date), 'HH:mm', { locale: vi });
}

export function fDateTime(date) {
  return format(new Date(date), 'dd-MM-yyyy hh:mm:ss', { locale: vi });
}

export function fTimestamp(date) {
  return getTime(new Date(date), { locale: vi });
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p', { locale: vi });
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: vi
  });
}
