import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShopIcon from '@mui/icons-material/Shop';
import AssistantIcon from '@mui/icons-material/Assistant';
import SettingsIcon from '@mui/icons-material/Settings';
import { alpha } from '@mui/material/styles';
import {
  Avatar,
  Button,
  Box,
  Divider,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Stack,
  Typography,
  ListItemAvatar
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useLocales from '../../hooks/useLocales';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { translate } = useLocales();

  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);

  const MENU_OPTIONS = [
    {
      label: translate('orders.index'),
      sub: translate('orders.info'),
      icon: <ShopIcon />,
      linkTo: PATH_DASHBOARD.orders.root
    },
    {
      label: translate('audits.index'),
      sub: translate('audits.info'),
      icon: <AccountBalanceWalletIcon />,
      linkTo: PATH_DASHBOARD.audits.root
    },
    {
      label: translate('tickets.index'),
      sub: translate('tickets.info'),
      icon: <AssistantIcon />,
      linkTo: PATH_DASHBOARD.tickets.root
    },
    {
      label: translate('settings.index'),
      sub: translate('settings.info'),
      icon: <SettingsIcon />,
      linkTo: PATH_DASHBOARD.settings.root
    }
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/auth/login');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  const handleListItemClick = (event, index) => {
    setOpen(false);
    navigate(index);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar
          src="/assets/img/avatar-user.png"
          sx={{
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'primary.main'
          }}
        />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ backgroundColor: 'primary.lighter', py: 2.5, mt: 1.5, px: 2.5 }}>
          <Stack direction="row" alignItems="center">
            <MyAvatar
              src="/assets/img/avatar-user.png"
              sx={{
                mr: 2.5,
                width: { xs: 60, md: 60 },
                height: { xs: 60, md: 60 },
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: 'primary.main'
              }}
            />
            <Stack direction="column">
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user.phone}, {user.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user.email}
              </Typography>
              <Typography sx={{ color: 'primary.main' }} variant="subtitle1" noWrap>
                {user.code} - {user.project.toUpperCase()}
              </Typography>
            </Stack>
          </Stack>
        </Box>

        <Divider sx={{ mt: 1.5 }} />

        <List component="nav">
          {MENU_OPTIONS.map((option, index) => (
            <ListItemButton
              key={index}
              alignItems="center"
              onClick={(event) => handleListItemClick(event, option.linkTo)}
            >
              <ListItemAvatar sx={{ color: 'primary.main' }}>
                <Avatar sx={{ bgcolor: 'primary.lighter', color: 'primary.main' }}>{option.icon}</Avatar>
              </ListItemAvatar>
              <ListItemText sx={{ color: 'primary.main' }} primary={option.label} secondary={option.sub} />
            </ListItemButton>
          ))}
        </List>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1 }}>
          <Button fullWidth color="primary" variant="outlined" onClick={handleLogout}>
            {translate('auth.logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
