import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useCallback, useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import DynamicFormSharpIcon from '@mui/icons-material/DynamicFormSharp';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ShopIcon from '@mui/icons-material/Shop';
import AssistantIcon from '@mui/icons-material/Assistant';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentReturnSharpIcon from '@mui/icons-material/AssignmentReturnSharp';
import AnalyticsSharpIcon from '@mui/icons-material/AnalyticsSharp';
import ArrowDropDownCircleSharpIcon from '@mui/icons-material/ArrowDropDownCircleSharp';
import { alpha } from '@mui/material/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Stack,
  Typography,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// utils
import axios from '../../utils/axios';
import mockData from '../../utils/mock-data';
import { fDateTime, fToNow } from '../../utils/formatTime';
// hooks
import useLocales from '../../hooks/useLocales';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired
};

function NotificationItem({ item, index }) {
  const isLastItem = index !== item.length - 1;

  let icon = <FeaturedPlayListIcon />;
  switch (item.type) {
    case 'pickups':
      icon = <DynamicFormSharpIcon />;
      break;

    case 'returns':
      icon = <ArrowDropDownCircleSharpIcon />;
      break;

    case 'audits':
      icon = <AccountBalanceWalletIcon />;
      break;

    case 'tickers':
      icon = <AssistantIcon />;
      break;

    default:
      icon = <FeaturedPlayListIcon />;
      break;
  }

  return (
    <ListItem alignItems="center" divider={isLastItem}>
      <ListItemAvatar sx={{ px: 2, color: 'primary.main' }}>
        <Avatar sx={{ bgcolor: 'primary.lighter', color: 'primary.main' }}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ fontWeight: 600, color: 'text.secondary' }}
        primary={
          <Typography variant="body2" color="text.secondary">
            {item.content}
          </Typography>
        }
        secondary={
          <Stack direction="column" sx={{ mt: 0.75 }}>
            <Typography sx={{ fontSize: 12 }} variant="body2" color="primary.main">
              {fToNow(item.created_at)} / {fDateTime(item.created_at)}
            </Typography>
          </Stack>
        }
      />
    </ListItem>
  );
}

export default function NotificationsPopover() {
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [totalUnRead, setTotalUnRead] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getNotifications = async () => {
    try {
      const response = await axios.get('/notifications/unread');
      if (isMountedRef.current) {
        setNotifications(response.data.data.notifications.data);
        setTotalUnRead(response.data.data.notifications.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNotifications();

    const interval = setInterval(() => getNotifications(), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleMarkAllAsRead = () => {
    markAllAsRead();
  };

  const markAllAsRead = async () => {
    try {
      const response = await axios.post('/notifications/readall');
      if (isMountedRef.current) {
        setTotalUnRead(0);
        setNotifications([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notifications.index')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('notifications.info', { totalUnRead })}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title={translate('notifications.markreadall')}>
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        {totalUnRead > 0 && (
          <>
            <Divider />

            <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
              <List disablePadding>
                {notifications.map((notification, index) => (
                  <NotificationItem index={index} key={notification.id} item={notification} />
                ))}
              </List>
            </Scrollbar>

            <Box sx={{ p: 1 }}>
              <Button
                onClick={handleClose}
                color="primary"
                variant="outlined"
                fullWidth
                disableRipple
                component={RouterLink}
                to={PATH_DASHBOARD.settings.notifications}
              >
                {translate('notifications.viewall')}
              </Button>
            </Box>
          </>
        )}
      </MenuPopover>
    </>
  );
}
